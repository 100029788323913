import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/st_gallen/unisg-min.png"
import unisg1 from "../../../assets/images/portfolio_items/st_gallen/unisg1.png"
import unisg2 from "../../../assets/images/portfolio_items/st_gallen/unisg2.png"
import unisg3 from "../../../assets/images/portfolio_items/st_gallen/unisg3.png"
import unisg4 from "../../../assets/images/portfolio_items/st_gallen/unisg4.png"
import unisg5 from "../../../assets/images/portfolio_items/st_gallen/unisg5.png"
import unisg6 from "../../../assets/images/portfolio_items/st_gallen/unisg6.png"

export default () => 
<div>
    <SEO title={'University ICON Worldwide | University of St.Gallen - Drupal Development St. Gallen'} 
    description="Not a simple Drupal Development project: a wide redesign for the University of St.Gallen. Discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/university-of-st-gallen'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
            <div className="title"><h1>University of St.Gallen<span></span></h1>
            <h2>Not a simple Drupal Development project</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>One of the leading business schools in the German-speaking region. ICON undertook a redesign of many top programs targeting intonational rankings. Our work included concept, design, collateral, social media and multiple Drupal Website.</p>
        <div className="portfolio-images">
            <img src={unisg1} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
            <img src={unisg2} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
            <img src={unisg3} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
            <img src={unisg4} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
            <img src={unisg5} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
            <img src={unisg6} alt="University of St.Gallen, ICON Worldwide, Drupal Development, portfolio" title="ICON Worldwide portfolio, Drupal Development, University of St.Gallen"/>
        </div>
        <WorkFooter previous="car4you" next="credit-suisse-football"/>
        </div>
    </div>
</div>